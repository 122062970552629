/*
This CSS resource incorporates links to font software which is...
...the valuable copyrighted property of Monotype and/or its suppliers.
...You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software.
Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/
@font-face {
    font-family:"Avenir"; // Book
    src:url("/fonts/1475508/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
    src:url("/fonts/1475508/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("/fonts/1475508/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("/fonts/1475508/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("/fonts/1475508/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family:"Avenir"; // Book Oblique
    src:url("/fonts/1475514/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix");
    src:url("/fonts/1475514/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix") format("eot"),url("/fonts/1475514/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),url("/fonts/1475514/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff"),url("/fonts/1475514/04d1bf6c-070d-4b7e-9498-6051c9f0c349.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {  // Medium
    font-family:"Avenir";
    src:url("/fonts/1475532/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
    src:url("/fonts/1475532/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"),url("/fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("/fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"),url("/fonts/1475532/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype");
    font-weight: 500;
}
@font-face { // Medium Oblique
    font-family:"Avenir";
    src:url("/fonts/1475538/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix");
    src:url("/fonts/1475538/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix") format("eot"),url("/fonts/1475538/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"),url("/fonts/1475538/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff"),url("/fonts/1475538/cee053ec-4b41-4506-a1fe-796261690610.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face { // "Black"
    font-family:"Avenir";
    src:url("/fonts/1475556/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
    src:url("/fonts/1475556/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"),url("/fonts/1475556/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),url("/fonts/1475556/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),url("/fonts/1475556/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype");
    font-weight: 800;
}
@font-face { // "Black" Oblique
    font-family:"Avenir";
    src:url("/fonts/1475562/4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix");
    src:url("/fonts/1475562/4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix") format("eot"),url("/fonts/1475562/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2") format("woff2"),url("/fonts/1475562/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff") format("woff"),url("/fonts/1475562/5ba5a010-7470-4d9d-8a49-2920dc1be1f8.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

html {
    color: $color-main;
    font-family: $font-serif;
    @include breakpoint(bp-medium) {
        font-size: 112.5%; // =1.125em (so 1rem (and 1em on any element without a dedicated font-size) = 18px. And 27px (18*1.5) is the new "basic unit of vertical rhythm")
    }
    /*@include breakpoint(bp-large) {
        font-size: 125%; // =1.25em (so 1rem (and 1em on any element without a dedicated font-size) = 20px. And 30px (20*1.5) is the new "basic unit of vertical rhythm")
    }*/
}

body {
    font-feature-settings: "liga" 1;
    font-feature-settings: "onum" 1;
    -webkit-font-smoothing: antialiased;

    line-height: $base-line-height;
    @include breakpoint(bp-xsmall) {
        line-height: $bp-xs-base-line-height;
    }
    @include breakpoint(bp-small) {
        line-height: $bp-s-base-line-height;
    }
    @include breakpoint(bp-medium) {
        line-height: $bp-m-base-line-height;
    }
    @include breakpoint(bp-large) {
        line-height: $bp-l-base-line-height;
    }
    @include breakpoint(bp-xlarge) {
        line-height: $bp-xl-base-line-height;
    }
}
@supports (font-variant-ligatures: common-ligatures) {
    body {
        font-feature-settings: normal;
        font-variant-ligatures: common-ligatures;
    }
}
@supports (font-variant-numeric: oldstyle-nums) {
    body {
        font-feature-settings: normal;
        font-variant-numeric: oldstyle-nums;
    }
}

a {
    color: $color-accent;
    text-decoration: underline;
    //text-decoration: none;
    //border-bottom: (1/$base-font-size)+rem solid $color-accent; // $section-header-bg-three
    &:hover {
        color: darken($color-accent, 20%);
    }
}

small {
    @include font-size-to-rem($smallest-font-size);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
    }
}

p {
    @include paragraph-style-margins();
    /* max-width: 38em; // Choose a comfortable measure */
    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height/2)+em 0;
    }
}

blockquote, 
ul,
ol {
    @include paragraph-style-margins();
    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height)+em 0;
    }
}

ul ul, 
ol ol {
    margin-top: 0;
}

li {
    list-style-position: inside;
}

ul li {
    padding-left: .75em;
    li {
        padding-left: 1.5em;
    }
}

article li {
    @include paragraph-style-margins();
}

@include breakpoint(bp-medium) {
    ol {
        list-style: none;
        counter-reset: mylist;
        li {
            list-style-position: outside;
            &::before {
                counter-increment: mylist;
                content: counter(mylist);
                margin-left: -1.25+em;
                margin-right: .75em;
            }
        }
    }
}

dt {
    font-weight: 600;
}

dd {
    @include paragraph-style-margins();
    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height)+em 0;
    }
    margin-top: 0;
}
/*p, 
ul, 
ol, 
dl, 
table {*/
    /* @include breakpoint(bp-xxsmall) {
        @include font-size-to-rem(17);
    }*/
    /*@include breakpoint(bp-medium) {
        @include font-size-to-rem(18);
    }*/
    /*@include breakpoint(bp-large) {
        @include font-size-to-rem(20);
    }*/
    // Would like to do the following too but requires ability to test on a big screen (and bumping up and testing all other font sizes too).
    /*@include breakpoint(bp-xlarge) {
        @include font-size-to-rem(21); // or 23
    }*/
/*}*/

// Assuming we've set our language in the <html> element, turn on automatic hyphenation in CSS.
/*h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
li,
dd {
    hyphens: auto;
}*/

h1, h2, h3, h4, h5, h6 {
    color: $color-interface-and-display;        //$color-main;
    font-family: $font-sans;

    font-feature-settings: "dlig" 1;
    @supports (font-variant-ligatures: discretionary-ligatures) {
        .dlig {
            font-feature-settings: normal;
            font-variant-ligatures: discretionary-ligatures;
        }
    }
}

// Headings, starting with smallest first.
h6,
h5, 
h4 {
    font-weight: 800;
    font-size: 1rem;
    letter-spacing: -0.01em;

    // line-height: 1.125; // =18px                // @include rhythmic-line-height(16); | this didn't work; line-height of 1.5 is too loose for our bold, sans heading font at this font-size.
    // Let's assume <h4>s (etc) will normally only require one line 
    // and account for the extra 6px (24-18) we need to maintain vertical rhythm on a single-line h4
    // margin-top: ((24+6)/16)+em;
    // margin-bottom: (24/16)+em;

    // The above is too complicated. Let's just go back to standard rhythmic.
    // @include rhythmic-line-height($base-font-size); // don't need this since font-size is 1 rem so will scale with the root element.
    margin: $base-line-height+em 0 0; // all the required VR-maintaining space at the top; none at the bottom
}
h3 {
    font-weight: 800;
    @include font-size-to-rem(18); // was 21 - maybe go for that at iphone7
    letter-spacing: -0.01em;

    // line-height: 1.1666666667; // =21px         // @include rhythmic-line-height(18); | this didn't work; it's too loose for our bold, sans font at this font-size.    
    // Let's assume <h3>s will normally only require one line 
    // and account for the extra 3px (24-21) we need to maintain vertical rhythm on a single-line h3      // min-height: 24px; // @include rhythmic-vertical-margins(18);
    // margin-top: ((24+3)/18)+em;
    // margin-bottom: (24/18)+em;
    
    // let's go back to rhythmic stuff. It looks better in teaser links. And h3s in flowing prose won't require 2 lines very often.
    @include rhythmic-line-height(18); 
    // @include rhythmic-vertical-margins(18);
    margin: ($base-line-height-in-px / 18)+em 0 0; // all the required space at the top; none at the bottom

    @include breakpoint(bp-medium) {
        @include font-size-to-rem(25, $bp-m-base-font-size);
        @include rhythmic-line-height(25, $bp-m-px-base-line-height); 
        margin: ((($bp-m-px-base-line-height / 25)/4)*3)+em 0 (($bp-m-px-base-line-height / 25)/4)+em;
    }
}
%h2,
h2 {
    font-weight: 800;
    @include font-size-to-rem(21); // was 25 - maybe go for that at iphone-7
    letter-spacing: -0.01em;
    // the following (vertical-rhythm-maintaining) line spacing looks OK for our bold, sans heading font at this font-size. We don't need tighter tracking. 
    @include rhythmic-line-height(21);
    margin: ($base-line-height-in-px / 21)+em 0 0; // all the required space at the top; none at the bottom   // @include rhythmic-vertical-margins(21); 

    @include breakpoint(bp-medium) {
        @include font-size-to-rem(33, $bp-m-base-font-size);
        @include rhythmic-line-height(33, $bp-m-px-base-line-height*1.5); // Need to set tracking to something that looks OK rather than something that maintains VR
        margin: ($bp-m-px-base-line-height / 33)+em 0 0; // margin at top but not bottom.      //margin: ($bp-m-px-base-line-height / 33)+em 0;
    }
}
%h1, 
h1 {
    font-weight: 800;
    @include font-size-to-rem(33); 
    
    // Avenir bold/sans at this font-size benefits from tighter tracking. 
    // I've set a little looser than solid (line-height=1) because that causes ascenders and descenders to touch.
    // I've also attempted maintaining vertical rhythm by i) asserting that on small viewports <h1>s generally span 2 lines; and ii) making the 2 lines take up 72px, a multiple of 24  
    line-height: ((72/2)/33);

    @include rhythmic-vertical-margins(33);
    letter-spacing: -0.02em; // Gently tighten big, bold and wide fonts

    @include breakpoint(bp-medium) {
        @include font-size-to-rem(51, $bp-m-base-font-size);
        @include rhythmic-line-height(51, $bp-m-px-base-line-height*2);
        margin: (($bp-m-px-base-line-height / 51)*2)+em 0 ($bp-m-px-base-line-height / 51)+em;      // @include rhythmic-vertical-margins(51, $bp-m-px-base-line-height);
    }
}
/*%single-line-h1 { // might be used by a short heading in e.g. a hero component where we know that even on mobile size it'll only take up one line, and we want nice vertical rhythm
    @extend %h1;
    line-height: (48/33); //=48px
}*/


%nice-body-letter-spacing {
    letter-spacing: -.0125em;
}
article {
    p,
    li,
    dt,
    dd {
        /*@extend %nice-body-letter-spacing;*/
        hyphens: auto;
    }
}

// Mark the opening of each passage of text

// All small caps opening line
//.article-body > p:first-child::first-line {
//    // for legacy browsers
//    font-feature-settings: "smcp" 1;
//}
//@supports (font-variant-caps: small-caps) {
//    .article-body > p:first-child::first-line {
//        font-feature-settings: normal;
//        font-variant-caps: small-caps;
//    }
//}

// Drop-Cap opening letter
@supports(-webkit-initial-letter: 2) {
    .article-body > p:first-child::first-letter {
        -webkit-initial-letter: 2;
        initial-letter: 2;
        font-weight: bold;
        margin-right: .25em;
    }
}

.byline {
    /*color: $color-light;*/
    @include font-size-to-rem($smallest-font-size);
    @include rhythmic-line-height($smallest-font-size);
    font-weight: 300;
    font-family: $font-sans;
    margin: 0;
    time:before {
        content: " · ";
    }
    @include breakpoint(bp-medium) {
        @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
        @include rhythmic-line-height($bp-m-smallest-font-size, $bp-m-px-base-line-height);       
    }
}

.byline-additional {
    display: block;
}

.standfirst {
    font-style: italic;
    margin: $base-line-height+em 0;
    //border: 1px dashed $color-main;
    //border-width: 1px 0;
    //padding: 1.5em 0;
}

blockquote { //.quoted
    padding-left: $base-line-height+em;
    p {
        font-style: italic;
        hanging-punctuation: first last;
        quotes: '“' '”' '‘' '’';
        &:first-of-type::before {
            content: open-quote;
            margin-left: -0.83ch;
        }
    }
    footer {
        text-align: right;
        .cite-and-pubdate {
            display: block;
        }
    }   
}

@supports(hanging-punctuation: first last) {
    blockquote p:first-of-type::before {
        margin-left: 0;
    }
}

blockquote p:last-of-type::after  {
    content: close-quote;
}

blockquote p:last-of-type {
    margin-bottom: 0;
}

.client-testimonial {
    cite {
        display: block;
        @include paragraph-style-margins();
    }
}

