// 
// Additional variables
//

$logo-width-plus-one-lineheight-as-em: (150 + ($bp-m-base-font-size * $bp-m-base-line-height)) / $bp-m-base-font-size;


//
// Additional mixins
//

// Returns our standard grid layout dimensions so that they can be reused in multiple areas of the page.
@mixin gh-standard-grid-cols-template() {
    grid-template-columns: 
        [full-start] minmax(1em, 1fr)
        [inner-two-start] $logo-width-plus-one-lineheight-as-em+em
        [main-start] 65vw
        [main-end] $logo-width-plus-one-lineheight-as-em+em
        [inner-two-end] minmax(1em, 1fr) 
        [full-end];
}


// Introducing CSS Grid
// Goal:
// 1) provide a platform to make the layout more interesting (e.g. multi-columnar) in a way that couldn’t be done with old techniques or would require "hacky" layout techniques.
// 2) make it easier to introduce "real whitespace", for example in the header
// 3) update the website to use the modern way of layout because i) it keeps it modern; and ii) it shows off that we can 'do' grid with progressive enhancement

// Plan
// Wrap grid-based enhancements in @supports grid
// Don‘t grid–ify stuff when there’s not a good reason to do so, e.g. <body>
// No need to apply to narrow mobile. Only start applying from the breakpoint-width at which there’s enough room to do something interesting.






@supports (display: grid) {
}