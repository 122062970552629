// Modern clearfix
    // or just use overflow: auto? https://meiert.com/en/blog/no-clearfix/
    // note: we shouldn't have a need for this at all if we don't use floats for layout.
.group:after {
  content: "";
  display: table;
  clear: both;
}

// Convert a target font-size in px (e.g. 24) to rem based on a given :root/base font size in px (e.g. 16).
@mixin font-size-to-rem($targetFontSize: $base-font-size, $baseFontSize: $base-font-size) {
  font-size: $targetFontSize + px; //fallback for old browsers
  font-size: ($targetFontSize / $baseFontSize) + rem;
}

// set the correct line height for a given font size and unit of vertical rhythm
@mixin rhythmic-line-height($fontSize: $base-font-size, $lineHeightInPx: $base-line-height-in-px) {
  line-height: ($lineHeightInPx / $fontSize);
}

// Separate paragraphs by no more than 1em. Half the line-height should be sufficient.
@mixin paragraph-style-margins() {
  margin: ($base-line-height/2)+em 0; // margin: $base-line-height+em 0;
}

// set vertical margins for a given font size and unit of vertical rhythm
@mixin rhythmic-vertical-margins($fontSize: $base-font-size, $lineHeightInPx: $base-line-height-in-px) {
  margin: ($lineHeightInPx / $fontSize)+em 0;
}


// Include a media query
// Usage: @include breakpoint(small);
@mixin breakpoint($point) {
  @if $point == bp-xlarge {
    @media only screen and (min-width: $bp-xlarge) { @content; }
  }
  @if $point == bp-large {
    @media only screen and (min-width: $bp-large) { @content; }
  }
  @else if $point == bp-medlarge {
    @media only screen and (min-width: $bp-medlarge) { @content; }
  }
  @else if $point == bp-medium {
    @media only screen and (min-width: $bp-medium) { @content; }
  }
  @else if $point == bp-small {
    @media only screen and (min-width: $bp-small)  { @content; }
  }
  @else if $point == bp-xsmall {
    @media only screen and (min-width: $bp-xsmall)  { @content; }
  }
  @else if $point == bp-xxsmall {
    @media only screen and (min-width: $bp-xxsmall)  { @content; }
  }
}

// Serve HiDPI background images from any selector you’d like, just by creating two image assets and one line of SCSS
// Usage: @include retinize('icon-dribbble', 'png', 24px, 24px);
@mixin retinize($file, $type, $width, $height) {
  background-image: url('../img/' + $file + '.' + $type);

  $is-hidpi: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx)";
  @media #{$is-hidpi} {
    & {
      background-image: url('../img/' + $file + '@2x.' + $type);
      background-size: $width $height;
    }
  }
}

//
// Usage: @include linear-gradient(#42b3e2, #1a6798);
@mixin linear-gradient($from, $to) {
  /* Fallback for sad browsers */
  background-color: $to;
  /* None of this should need to be explicit because we're including autoprefixer in our gulp-sass workflow.
  background-image:-moz-linear-gradient($from, $to);
  background-image:-o-linear-gradient($from, $to);
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to));
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -ms-linear-gradient($from, $to); */
  /* W3C */
  background-image: linear-gradient($from, $to);
}
