@import "normalise.scss";
@import "reset.scss";
@import "variables.scss";
@import "mixins.scss";
@import "custom_general.scss";
@import "general_typography.scss";
@import "forms.scss";


// 
// Additional variables
//

$simple-content-column-width-ems-bp-small: 36;
$simple-content-column-width-ems-bp-medium: 40;


//
// Additional mixins
//

@mixin simple-lateral-margins() {
    margin-left: 5vw;
    margin-right: 5vw;
}

@mixin simple-lateral-padding() {
    padding-left: 5vw;
    padding-right: 5vw;
}


// 
// Responsive images
// 

img {
    max-width: 100%;
}


// 
// Tables 
// 

// For consistency and ease of understanding, match the alignment of headings to the alignment of the data.
// Right-align headings of numeric data and left-align headings of columns with text.

table {
    font-variant-numeric: lining-nums tabular-nums;
    @include paragraph-style-margins();
}
tfoot {
    display: none;
}
.fig-table {
    max-width: 100%;
    overflow-x: scroll;
}


// 
// Alerts
// 

%alert {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
[role=alert],
.message,
.alert-success,
.alert-error {
    @extend %alert;
}
.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.alert-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}


// 
// Tabs
// 

[role=tab] {
    display: inline-block;
}
[role=tab][aria-selected=true] {
    background: tomato;
}
[role=tabpanel] {
    display: none;
}
[role=tabpanel][aria-expanded=true] {
    display: block;
}


// 
// Utilities / Common
// 

// In some (most) situations we want images to display block
article img, 
figure img {
    display: block;
}

figure {
    margin: ($base-line-height)+em 0;
    figcaption {
        color: $color-light;
        font-family: $font-sans;
        @include font-size-to-rem($smallest-font-size);
        // 18+6=24=BUoVR
        line-height: (18/$smallest-font-size)+em; 
        margin: (6/$smallest-font-size)+em 0 0; 
        @include breakpoint(bp-medium) {
            @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
            // 18+6+3=27=(BUoVR at medium breakpoint)
            margin: (6/$bp-m-smallest-font-size)+em 0 (3/$bp-m-smallest-font-size)+em; 
        }
    }
}

.gh-figure {
    @include breakpoint(bp-small) {
        max-width: 19em;
        float: left;
        margin: $base-line-height+em $base-line-height+em $base-line-height+em (-$base-line-height)+em;
    }
    @include breakpoint(bp-medium) {
        margin-left: (-(5*$base-line-height))+em;
    }
}

.gh-figure-alt {
    @include breakpoint(bp-small) {
        float: right;
        margin-right: (-(2*$base-line-height))+em;
        margin-left: $base-line-height+em;
    }
    @include breakpoint(bp-medium) {
        margin-right: (-(5*$base-line-height))+em;
    }
}

.gh-figure-highlight {
    @extend %full-viewport-width;
    figcaption {
        @include simple-lateral-margins();
        @include breakpoint(bp-small) {
            width: (($simple-content-column-width-ems-bp-small * $base-font-size) / $smallest-font-size)+em;
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(bp-medium) {
            width: (($simple-content-column-width-ems-bp-medium * $bp-m-base-font-size) / $smallest-font-size)+em;
        }
    }        
}

// Banner

.banner {
    @extend %full-viewport-width;
    margin-top: 0;
    margin-bottom: 0;
    img {
        width: 100%;
    }
    @include breakpoint(bp-xsmall) {
        img {
            height: 18.75vw; // 56.25% maintains the correct height for 16:9. 18.75 is a third of that, so we can have the banner be a third as tall.
            object-fit: cover;
            object-position: 50% 5%;
        }
    }
    figcaption {
        @include simple-lateral-margins();
        @include breakpoint(bp-small) {
            width: (($simple-content-column-width-ems-bp-small * $base-font-size) / $smallest-font-size)+em;
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(bp-medium) {
            width: (($simple-content-column-width-ems-bp-medium * $bp-m-base-font-size) / $smallest-font-size)+em;
        }
    }
}

// use for containers with coloured backgrounds in which the first and/or last child elements need margin and we need to see the container‘s background in that margin.
%container-with-coloured-background {
    overflow: auto;
}

// make an element which is a child of a narrow container break out (extend) to full viewport width.
%full-viewport-width {
    // Ref: https://css-tricks.com/full-width-containers-limited-width-parents/ (Sven Wolfermann https://codepen.io/maddesigns/pen/rOMgpQ/)
    // push to the exact middle of the browser window with left: 50%, then pull it back to the left edge with negative -50vw margin.
    // note 1: requires the parent to be exactly centered in the browser.
    // note 2: I’ve taken out the 'right' and 'margin-right' properties because they’re only needed if your site might use direction: rtl
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
}

.highlighted-section {
    @extend %container-with-coloured-background;
    @extend %full-viewport-width;
    background-color: $color-background-light;
}

// Pullquote
.pullquote {
    @extend %full-viewport-width;
    @include simple-lateral-padding();
    background-color: $color-background-light;
    padding-top: ($base-line-height*2)+em;
    padding-bottom: ($base-line-height*2)+em;
    margin-top: $base-line-height+rem;
    margin-bottom: $base-line-height+rem;
    text-align: center;
    p {
        hyphens: none;
        margin: 0;
        @include font-size-to-rem(21);
        @include breakpoint(bp-small) {
            max-width: (($simple-content-column-width-ems-bp-small * $base-font-size) / 21)+em; // where 21 is font-size
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(bp-medium) {
            @include font-size-to-rem(33, $bp-m-base-font-size);
            width: (($simple-content-column-width-ems-bp-medium * $bp-m-base-font-size) / 33)+em;
        }
    }
    footer {
        font-weight: 800;
        font-family: $font-sans;
        color: $color-accent;
        margin-top: .75em; // just looks good by eye.
        @include font-size-to-rem($smallest-font-size);
        line-height: 1.2;
        @include breakpoint(bp-small) {
            width: (($simple-content-column-width-ems-bp-small * $base-font-size) / $smallest-font-size)+em; // where $smallest-font-size is element font-size.
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(bp-medium) {
            @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
            width: (($simple-content-column-width-ems-bp-medium * $bp-m-base-font-size) / $bp-m-smallest-font-size)+em;
        }

    }
}

// text styles in article-style pages.

.article-meta {
    font-style: italic;
    margin-top: $base-line-height+em;
}

#push-notifications-prompt {
    margin-top: ($base-line-height*2)+em;
}

// teaser list styles

.wrapping-anchor {
    text-decoration: none;
    color: inherit;
}

.teaser {
    margin: ($base-line-height*2)+em 0;
    & + .teaser {
        margin-top: ($base-line-height*3)+em;
    }
}

.teaser-heading {
    border-left: .16em solid $color-accent;
    padding-left: .5em;
    @include rhythmic-vertical-margins(21); // I'm assuming that .teaser-headings (NB not teaser-item-headings) are always H2s, so using the h2 font-size here.
    @include breakpoint(bp-medium) {
        line-height: 1; // 33px
        // Now use margin to make up the space needed to maintain VR at bp-medium (one base line-height added to top and bottom PLUS a share of 21px, based on 2*27=54-33).
        margin: (($bp-m-px-base-line-height +11) / 33)+em 0 (($bp-m-px-base-line-height +10) / 33)+em;
    }
}

.teaser-list {
    list-style: none;
    li {
        padding-left: 0;
    }
    img {
        display: block;
        margin: $base-line-height+em 0;
    }
    p:last-child {
        margin-top: 0;
        margin-bottom: ($base-line-height*2)+em;
    }
    @include breakpoint(bp-small) {
        li {
            margin: 0 0 $base-line-height+em;
        }
        li:last-child {
            margin-bottom: ($base-line-height*2)+em;
        }
        .teaser-group {
            display: flex;
            align-items: flex-start;
            > img {
                width: (280/$bp-s-base-font-size)+em;
                margin: 0 $base-line-height+em 0 0;
            }
            .teaser-text {
                flex: 1;
            }
            p:last-child {
                margin-bottom: ($base-line-height*.75)+em;
            }
        }
    }

}

// Section headers

// Section headers
// At smallest resolution our basic unit of vertical rhythm (one line-height) is 24px and all component spacing here is based on maintaining that rhythm. 
// we've set the component's font-size in order to provide context so that we can easily give the container a bottom margin based on current unit of vertical rhythm.
// At larger viewports if we've changed our body font size and line-height (basic unit of vertical rhythm) we can adjust here.
#section-header {
    @extend %full-viewport-width;
    background-color: $section-header-bg-one;
    font-size: 1rem;
    text-align: center;
    padding: 1em 0 .5em;
    margin-bottom: $base-line-height+em;
    h1, 
    p {
        color: #fff;
        //max-width: 85%;
        @include breakpoint(bp-xxsmall) {
            max-width: 80%;
        }
    }
    h1 {
        @include font-size-to-rem(48);
        line-height: 1;
        padding-bottom: .5em; //=24px
        margin: (60/48)+em auto (12/48)+em; // combined margin of 60+12=72 i.e. maintains vertical rhythm.
        position: relative;
        &:after { // the dividing line at the bottom (with help from https://css-tricks.com/forums/topic/horizontal-centering-of-an-absolute-element/)
            content: "";
            background-color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            width: 1em;
            height: 1px;
            margin: 0 auto;
        }
        @include breakpoint(bp-small) {
            max-width: (24/(48/16))+em; // equivalent to 24rem (where rem is initial val of 16) which works well as a width here.
        }
        @include breakpoint(bp-medium) {
            @include font-size-to-rem(54, $bp-m-base-font-size); // "set text at 'display sizes' i.e. big, where big means at least 3x text size (54=3*18)"
            padding-top: 0;
            padding-bottom: ($bp-m-px-base-line-height/54)+em;
            margin: (66/54)+em auto (15/54)+em; // combined margin of 66+15=81 i.e. maintains vertical rhythm.
        }
    }
    p {
        @include font-size-to-rem(18);
        @include rhythmic-line-height(18);
        font-style: italic;
        margin: 0 auto ($base-line-height-in-px*2 / 18)+em;
        @include breakpoint(bp-small) {
            max-width: (24/(18/16))+em; // equivalent to 24rem (original rem val of 16) which works well as a width here.
        }

    }
}

#page-portfolio-index #section-header {
    background: $section-header-bg-two;
}

#page-journal-index #section-header {
    background: $section-header-bg-three;
}



// 
// Page Layout
// 

// Define a simple base layout (a single column with gutters) before enhancing with CSS grid
main {
    @include simple-lateral-margins(); // base layout before enhancing with CSS grid
    margin-bottom: ($base-line-height * 4)+em;
    @include breakpoint(bp-small) {
        max-width: $simple-content-column-width-ems-bp-small+em;
        margin-left: auto;
        margin-right: auto;
    }
    @include breakpoint(bp-medium) {
        max-width: $simple-content-column-width-ems-bp-medium+em;
    }
}

// Header

// Row overall height:
// (mobile first) is 48px | Breakpoint medium is 54px

[role=banner] {
    font-family: $font-sans;
    @include font-size-to-rem(14);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem(14, 18);
    }    
    ul {
        margin: 0;
    }
    a {
        border: none;
    }
}

#header-global { // contains the logo, header-contact-methods, and global-nav elements
    display: flex;
    flex-wrap: wrap;
    @media only screen and (min-width: 64em) { //1024px. bp-medium (960px) isn’t wide enough for the nav to fit on top row.
        border-bottom: 1px solid rgb(238,238,238);
    }
}

#logo {
    flex-grow: 1;
    border-bottom: 1px solid rgb(238,238,238);
    padding-left: 5vw;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 64em) { //1024px. bp-medium (960px) isn’t wide enough for the nav to fit on top row.
        flex-grow: unset;
        border-bottom-width: 0;
        /*flex-basis: 50%;*/
        order: 1;
        //flex-basis: 33%;
    }
    a {
        display: block;
        @include retinize('greenhill-logo-mark', 'png', 26px, 26px);
        background-repeat: no-repeat;
        text-indent: -9999999px;
        // set dimensions as per physical logo image
        width: 26px;
        height: 26px;
        @include breakpoint(bp-medium) {
            @include retinize('greenhill-logo', 'png', 150px, 27px);
            // set dimensions as per physical logo image
            width: 150px;
            height: 27px;
        }
    }
}

#header-contact-methods {
    list-style: none;
    padding-right: 5vw;
    border-bottom: 1px solid rgb(238,238,238);
    border-left: 1px solid rgb(238,238,238);
    display: flex;
    align-items: center;
    @media only screen and (min-width: 64em) { //1024px. bp-medium (960px) isn’t wide enough for the nav to fit on top row.
        border-bottom-width: 0;
        order: 3;
        margin-left: auto;
    }
    li {
        margin: 0;
        padding: 0 0 0 $bp-m-base-line-height+em;
        &:last-child {
            border-right-width: 0;
        }
        @include breakpoint(bp-small) {
            border-right: 1px solid rgb(238,238,238);
            padding-right: $bp-m-base-line-height+em;
            &:last-child {
                border-right-width: 1px;
            }
        }
    }
    a {
        display: block;
        @include retinize('phone-icon', 'png', 16px, 15px);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-indent: -9999999px;
        width: 16px; // icon width
        line-height: (47/14)+em; //=47px
        @include breakpoint(bp-medium) {
            line-height: (53/14)+em; //=53px
        }
    }
    #header-contact-us {
        @include retinize('email-icon', 'png', 20px, 12px);
    }
    #header-visit-us {
        @include retinize('map-pin-icon', 'png', 10px, 15px);
    }
    #header-tel {
        @media only screen and (min-width: 80em) { // special-case breakpoint for header
            text-indent: 2em;
            width: 13.4em;
            background-position: 0 50%;
            text-decoration: none;
            color: rgb(74,74,74);
        }
    }
}

//
// Navigation
// 

[aria-label=site], 
[aria-label=department] {
    ul {
        list-style: none;
        display: flex;
        /*justify-content: space-between;*/
    }
    li {
        flex-grow: 1;
        padding: 0;
        border-right: 1px solid rgb(238,238,238);
        @include breakpoint(bp-medium) {
            /*border-right-width: 0;
            border-left: 1px solid rgb(238,238,238);*/
        }
        a {
            color: rgb(74,74,74);
            display: block;
            font-weight: 500;
            text-align: center;
            text-decoration: none;
            // now set line-height plus padding = 47px (rather than 48px because 1px is already made up of bottom border)
            line-height: 1;
            padding-top: (17/14)+em;
            padding-bottom: (16/14)+em;
            @include breakpoint(bp-medium) {
                // set padding such that line-height (14) plus padding = 53px (rather than 54px because 1px is already made up of bottom border)
                padding-top: (20/14)+em;
                padding-bottom: (19/14)+em;
            }
            @media only screen and (min-width: 64em) { //1024px. bp-medium (960px) isn’t wide enough for the nav to fit on top row.
                padding-left: $bp-m-base-line-height+em;
                padding-right: $bp-m-base-line-height+em;
            }
        }
        &:last-child {
            border-right-width: 0;
        }
        &:hover a, 
        &.active a {
            border-bottom: 4px solid $color-accent;
            margin-bottom: -1px;
            padding-bottom: (13/14)+em;
            @include breakpoint(bp-medium) {
                padding-bottom: (16/14)+em;
            }
        }
        &.active a {
            font-weight: 700;
        }
    }
}

// nav which wraps the #global-navigation list
[aria-label=site] { 
    border-bottom: 1px solid rgb(238,238,238);
    flex-basis: 100%;
    @media only screen and (min-width: 64em) { //1024px. bp-medium (960px) isn’t wide enough for the nav to fit on top row.
        order: 2;
        border-bottom-width: 0;
        flex-basis: auto;
        margin-left: $bp-m-base-line-height+em;
        li:first-child {
            border-left: 1px solid rgb(238,238,238);
        }
    }
}

// nav which wraps the #local-navigation list
[aria-label=department] { 
    background-color: $color-background-light;
    border-bottom: 1px solid rgb(238,238,238);
    li {
        &:first-child {
            border-left: 1px solid rgb(238,238,238);
        }
        &:last-child {
            border-right-width: 1px;
        }
    }
    @include breakpoint(bp-medium) {
        ul {
            justify-content: center;
            li {
                max-width: 10em;
            }
        }
    }
}

// Temporary space-saving measure 'til we get a better nav solution (maybe the BBC's "More" approach)
#nav-services {
    display: none;
    @include breakpoint(bp-small) {
        display: block;
    }
}


//
// Contact Prompt (that sits directly above footer on every page)
//

#contact-prompt {
    background-color: $color-background-light;
    @extend %container-with-coloured-background;
    padding-bottom: ($base-line-height)+em;
    #contact-prompt-inner {
        @include simple-lateral-margins();
        @include breakpoint(bp-small) {
            display: flex;
            max-width: $simple-content-column-width-ems-bp-small+em;
            margin: 0 auto;
        }
        @include breakpoint(bp-medium) {
            max-width: $simple-content-column-width-ems-bp-medium+em;
        }
    }
    @include breakpoint(bp-small) {
        padding-top: ($base-line-height*2)+em;
        padding-bottom: ($base-line-height*2)+em;
        figure {
            margin: 0 $base-line-height+em 0 0;
        }
        #contact-prompt-body {
            flex: 1;
        }
        h2 {
            margin-top: 0;
        }
        p {
            margin-bottom: 0;   
        }
    }
    img {
        border-radius: 50%;
        display: block;
        width: 50%;
        height: 50%;
        margin: $base-line-height*2+em auto 0;
        @include breakpoint(bp-small) {
            margin: 0;
            width: (175/$bp-s-base-font-size)+em;
            height: (175/$bp-s-base-font-size)+em;
        }
    }
    figcaption {
        text-align: center;
    }
}


//
// Footer (i.e. the global one at the bottom of each page, as opposed to any other local <footer> elements)
//

[role=contentinfo] {
    @extend %container-with-coloured-background;
    font-family: $font-sans;
    background-color: rgb(55,59,60);
    color: rgb(143,143,143);
    text-align: center;
    padding-top: ($base-line-height*2)+em;
    .legal {
        display: block;
        @include font-size-to-rem($smallest-font-size);
        @include rhythmic-line-height($smallest-font-size);
        @include breakpoint(bp-medium) {
            @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
            @include rhythmic-line-height(14, $bp-m-px-base-line-height);
        }
        &:last-of-type {
            display: inline-block;
            border-bottom: 2px solid $color-accent;
            padding: 0 .5em ((24-2)/14)+em; // -2 to maintain vertical rhythm by accounting for the 2px bottom border 
            @include breakpoint(bp-medium) {
                padding-bottom: ((27-2)/14)+em;
            }
        }
    }
    .copyright-notice {
        color: #fff;
    }
    a {
        border: none;
    }
}

#social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: 11em;
    margin-left: auto;
    margin-right: auto;
    margin-top: $base-line-height+em;
    margin-bottom: ($base-line-height*3)+em;
    line-height: (29/$base-font-size)+em; // 29 is the height of the tallest icon so use that as the uniform line-height.
    @include breakpoint(bp-medium) {
        line-height: (29/$bp-m-base-font-size)+em;
    }
    li {
        margin: 0;
        padding: 0;
        line-height: (29/$base-font-size)+em;
        @include breakpoint(bp-medium) {
            line-height: (29/$bp-m-base-font-size)+em;
        }
        a {
            display: block;
            @include retinize('linkedin-logo', 'png', 28px, 26px);
            background-repeat: no-repeat;
            text-indent: -9999999px;
            width: 28px;
            height: 26px;
            line-height: (29/$base-font-size)+em;
            @include breakpoint(bp-medium) {
                line-height: (29/$bp-m-base-font-size)+em;
            }
        }
    }
    #social-link-instagram a {
        @include retinize('instagram-logo', 'png', 29px, 29px);
        width: 29px;
        height: 29px;
    }
    #social-link-medium a {
        @include retinize('medium-logo', 'png', 30px, 26px);
        width: 30px;
    }
}


//
// Page: Home
//

.banner-hero {
    background-color: $color-accent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    @include breakpoint(bp-xsmall) {        
        padding-bottom: 56.25vw; // 56.25% maintains the correct height for 16:9 when we’re working with background images rather than an <img> with physical dimensions.
        position: relative;
        img {
            display: none; // NB now we're hiding the image and showing a background instead. NB the image won't be read by screen-readers but that's OK - the text is explanatory enough.
        }
    }
    .hero-detail {
        /*@extend %content-container-with-gutters;*/
        @include simple-lateral-margins();
        @extend %container-with-coloured-background;
        padding-bottom: $base-line-height+em;
        @include breakpoint(bp-xsmall) {
            width: auto;
            max-width: 19em;
            margin: 0;
            background: rgba(0,0,0,0.7);
            color: #fff;
            /*overflow: auto;*/
            padding: 0 $base-line-height+em $base-line-height+em;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        @include breakpoint(bp-medlarge) {
            top: 7.5em;
            bottom: auto;
            right: 7.5em;
            left: auto;
        }
        @include breakpoint(bp-large) {
            top: 12em;
            bottom: auto;
            right: 12em;
            left: auto;
        }
    }
    h3 { // the context indicator, e.g. "Portfolio Feature"
        color: #fff;
        text-transform: uppercase;
        border-left: .25em solid #fff;
        padding-left: .5em;
        @include font-size-to-rem(14);
        line-height: 1;
        margin: (29/14)+em 0; // maintains vertical rhythm (29+29+14=72 is a multiple of 24)
        @include breakpoint(bp-medium) {
            @include font-size-to-rem(14, $bp-m-base-font-size);
            margin: (33/14)+em 0 (34/14)+em; // maintains vertical rhythm (33+34+14=81 is a multiple of 27)
        }
    }
    h2 {
        @extend %h1;
        color: #fff;
        line-height: (48/33); //=48px
        @include breakpoint(bp-medium) {
            @include font-size-to-rem(54, $bp-m-base-font-size); // "set text at 'display sizes' i.e. big, where big means at least 3x text size (54=3*18)"
            @include rhythmic-line-height(54, $bp-m-px-base-line-height*2);
            @include rhythmic-vertical-margins(54, $bp-m-px-base-line-height);
        }
    }
    p {
        margin: $base-line-height+em 0;
    }
    .btn-more {
        color: #fff;
        border-color: #fff;
        @include breakpoint(bp-xsmall) {
            border-color: $color-accent;
        }
    }
}

#introduction {
    text-align: center;
    h1 {
        line-height: 1.1;
        margin: (($base-line-height-in-px*3)/33)+em 0; // a margin of 3 standard line-heights at top and bottom
        @include breakpoint(bp-medium) {
            margin: (($bp-m-px-base-line-height*3)/51)+em 0; // a margin of 3 standard line-heights at top and bottom
        }
    }
}

#introduction-body {
    @include simple-lateral-padding(); // base layout before enhancing with CSS grid
    @include breakpoint(bp-small) {
        padding: 0;
    }
    h2 {
        color: #4a4a4a;
        font-family: $font-serif;
        line-height: 1.5;
        font-weight: 400;
        font-style: italic;
        //max-width: 85%;
        //margin-left: auto;
        //margin-right: auto;
        @include font-size-to-rem(18);
        margin-top: ( ($base-line-height-in-px * 2) / 18 )+em;
        margin-bottom: ( ($base-line-height-in-px * 1.5) / 18 )+em;
        @include breakpoint(bp-small) {
            max-width: (($simple-content-column-width-ems-bp-small * $base-font-size) / 18)+em; // where 18 is font-size
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(bp-medium) {
            @include font-size-to-rem(25, $bp-m-base-font-size);
            max-width: (($simple-content-column-width-ems-bp-medium * $bp-m-base-font-size) / 25)+em;
            margin-top: ((($bp-m-base-font-size * $bp-m-base-line-height)*2) / 25)+em;
            margin-bottom: 1.5em;
        }   
    }
    .btn-more {
        margin-top: 0;
        margin-bottom: ( ($base-line-height-in-px * 3) / 18)+em;
        margin-left: auto;
        margin-right: auto;
    }
}



//
// Page: Contact
//

.map {
    margin: $base-line-height+em 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.map iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@import "layout_enhanced.scss";
