// Forms

form {
    @include paragraph-style-margins();
    margin: $base-line-height+em 0;

    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height)+em 0;
    }
}

// .form-group module (containing label, input, paddings and margin) 
// Mobile: takes up 96px (a multiple of our BUoVR 24). 
// 36 (18+18) in margin; 24 for label; 34 for input line-height; and 2px (1+1 input borders) 
// Medium breakpoint: takes up 108px (a multiple of our BUoVR 27). 
// 42 (21+21) in margin; 27 for label; 37 for input line-height; and 2px (1+1 input borders) 

button, 
.btn, 
input,
optgroup,
select,
textarea {
    font-family: $font-sans;
    font-weight: 300;
    line-height: (34/16)+em;

    @include breakpoint(bp-medium) {
        line-height: (37/$bp-m-base-font-size)+em; // =37/18
    }
}
fieldset {
    border-width: 0;
    @include paragraph-style-margins();
    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height)+em 0;
    }
}
legend {}
label {}
.form-group { // applied to a <label> wrapping an input
    display: block;
    position: relative;
    /*clear: both;*/
    margin: (18/16)+em 0;
    @include breakpoint(bp-medium) {
        margin: (21/$bp-m-base-font-size)+em 0; // =37/18
    }
}
.label-text {
    cursor: pointer;
    display: block;
    font-family: $font-sans;
    text-transform: uppercase;
    font-weight: 800;
    @include font-size-to-rem($smallest-font-size);
    @include rhythmic-line-height($smallest-font-size);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
        @include rhythmic-line-height($bp-m-smallest-font-size, $bp-m-px-base-line-height); 
    }
}
input[type=text],
input[type=url],
input[type=password],
input[type=email],
input[type=tel],
input[type=number],
textarea, 
.custom-select {
    border: 1px solid #bbb;
    border-radius: 5px;
    /*margin-bottom: (12/16)+em;*/
}
input[type=text],
input[type=url],
input[type=password],
input[type=email],
input[type=tel],
textarea {
    width: 100%;
    padding: 0 .5em;
}
input[type=number] {
    // width: 4em;
}
select {
    width: 100%;
}

// .custom-select module (containing label, input, paddings and margin) 
// Mobile: .custom-select needs to be height: 34
// Medium breakpoint: .custom-select needs to be height: 37

.custom-select {
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    position: relative;
    display: block;
    select {
        box-sizing: border-box;
        appearance: none;
        background: none;
        border: 1px solid transparent;
        border-radius: .2em;
        box-sizing: border-box;
        line-height: 1.5;
        margin: 0;
        outline: none;
        padding: 5/16+em 2em 3/16+em .4em; // =padding: 5px 2em 3px .4em;
        @include font-size-to-rem($base-font-size);
        @include breakpoint(bp-medium) {
            @include font-size-to-rem($bp-m-base-font-size, $bp-m-base-font-size);
            padding: 5px 2em 3px .4em;
        }
    }
    option {
        font-weight: normal;
    }
    &::after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid #666;
        content: " ";
        display: block;
        margin-top: -3px;
        position: absolute;
        right: 1em;
        top: 50%;
        pointer-events: none;
        width: 0;
        height: 0;
        z-index: 2;
    }
}
.error-msg {
    display: none; // just for now
    color: #dc0014;
    font-style: italic;
    @include font-size-to-rem(14);
    line-height: 1;
    margin: 5px 0; // 14+10=24
}

// button/.btn
// Mobile: 48=24+(padding: 6+6)+(margin: 6+6)
// Medium: 54=27+(padding: 7+7)+(margin: 7+6)

button, 
.btn {
    background-color: $color-interface-and-display; //$color-main
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
    @include font-size-to-rem($smallest-font-size);
    font-weight: 600;
    @include rhythmic-line-height($smallest-font-size);          //line-height: 1;
    padding: (6 / $smallest-font-size)+em 3em; // /*padding: .75em 3em;*/
    margin:  (6 / $smallest-font-size)+em 0;
    text-align: center;
    &:hover {
        background-color: $color-accent;
        color: #fff; //$color-main;
    }
    @include breakpoint(bp-medium) {
        @include font-size-to-rem($bp-m-smallest-font-size, $bp-m-base-font-size);
        @include rhythmic-line-height($bp-m-smallest-font-size, $bp-m-px-base-line-height); 
        padding: (7 / $bp-m-smallest-font-size)+em 4em;
        margin:  (7 / $bp-m-smallest-font-size)+em 0 (6 / $bp-m-smallest-font-size)+em;
    }
}
button {
    border: 0;
    /*@include rhythmic-vertical-margins(14);*/
}

// .btn-more
// Mobile: 48=24+(4+4)+(2+2)+(6+6)
// Medium: 54=27+(5+5)+(2+2)+(7+6)

.btn {
    text-decoration: none;
    &.btn-more {
        color: $color-interface-and-display; //$color-main;
        display: table; // inline-block wouldn't let me set a negative top-margin to compensate for the border
        background-color: transparent;
        border-radius: 0;
        border: 2px solid $color-accent;
        // account for the border (2px at top and bottom) by making the padding 2px less at top and bottom
        padding-top: (4/$smallest-font-size)+em;
        padding-bottom: (4/$smallest-font-size)+em;
        &:hover {
            background-color: $color-accent;
            border-color: darken($color-accent, 20%); //red
            color: #fff;
        }
        @include breakpoint(bp-medium) {
            padding-top: (5/$bp-m-smallest-font-size)+em;
            padding-bottom: (5/$bp-m-smallest-font-size)+em;
            margin-top: (7/$bp-m-smallest-font-size)+em;
        }    
    }
}
.submit-wrap {
    @include paragraph-style-margins();
    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height)+em 0;
    }
}
