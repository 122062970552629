// Richard Rutter's Reset
/* Reset */

html, body, section, nav, article, aside, h1, h2, h3, h4, h5, h6, hgroup, header, footer, address,
p, hr, pre, blockquote, ol, ul, li, dl, dt, dd, figure, figcaption, div,
a, em, strong, small, s, cite, q, dfn, abbr, time, code, var, samp, kbd, i, b, mark, span, br,
ins, del,
img, iframe, embed, object, video, audio, canvas, map, area,
table, caption, colgroup, tbody, thead, tfoot, tr, td, th,
form, fieldset, legend, label, input, button, select, textarea,
details, summary, command, menu {
    vertical-align: baseline;
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 100%;
}

.nobr {
    white-space: nowrap;
    -ms-hyphens:manual;
    -mov-hyphens:manual;
    -webkit-hyphens:manual;
    hyphens: manual;
}

.paren {
    font-style: normal;
}

.highlight, mark {
    background-color: rgba(255,255,0,0.5);
}

/* end Reset */
