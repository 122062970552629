// Colours
$color-main: rgba(0, 0, 0, 0.84); // same as Medium        									// prev:  // rgb(136,136,136); // (Oli's) // i.e. #888 //#444
$color-interface-and-display: rgba(0, 0, 0, 0.84); // rgba(74, 74, 74); // i.e. #4A4A4A

$color-accent: rgb(0,163,218); // i.e. #00A3DA                  							// prev:  //$color-accent: #4a90e2;
$color-light: rgb(136,136,136); // i.e. #888
$color-lighter: lighten($color-light, 30%);
$color-lightest: lighten($color-light, 40%);
$color-background-light: rgb(246,246,246); // i.e. #F6F6F6
$section-header-bg-one: rgb(77,182,172); // i.e. #4DB6AC
$section-header-bg-two: rgb(100,181,246); // i.e. #64B5F6
$section-header-bg-three: rgb(255,138,101); // i.e. #FF8A65



// Responsive Breakpoints
// Use ems rather than pixels to determine the screen width, so that:
// i) our layout adaptations are based on typographic reasoning rather than being tied to specific device widths
// ii) we've taken into account situations when the reader adjusted the default font size of their device. By using ems, our measurements scale proportionately with text size.
$bp-xxsmall:  23.4375em; // 375px, the width in portrait of iPhone 6/7/8
$bp-xsmall:  37.5em; // 600px, a boundary above which tall 3:4 images feel weird and we want to start using available width.
$bp-small:  48em; // 768px, tablet in portrait
$bp-medium:  60em; // 960px
$bp-medlarge:  90em; // 1440px
$bp-large:  105em; // 1680px
$bp-xlarge:  120em; // 1920px

// Note: if we're adapting layout based on typographic reasoning rather than specific device widths, ....
// shouldn't we set breakpoints at the points at which our body column becomes wider than that which is needed for a comfortable measure?
// We want a line-length of 45–75 characters. On average 1em accounts for 2 characters so...
// A max-width of 38em on paragraphs of font-size 16px restricts line width to the top end of the readable range. 
// Arguably 40em (or just under) should be the first breakpoint. At that point you reduce the number of characters in a line by either increasing font-size or introducing additional columns. 




// Typeface choices
$font-sans: 'Avenir', sans-serif;
$font-serif: 'PT Serif', Georgia, serif;



// Key Font Size Settings
// We're using this typographic scale: http://simplescale.online/?scale_base=16&scale_ratio=1.333&scale_interval=4
// with a ratio of 1.333 (decimal equivalent of 4:3) to work well with 4:3 photos
// we start with body copy font size at the default 16px, with a line height of 24px
// at viewport width 60em+ we increase body font size to 18px
// at viewport width 105em+ we increase body font size to 20px
// The following are maybes:
// at viewport width 23.4375em+ we increase body font size to 17px
// at viewport width 120em+ we increase body font size to 21px or 23px
$smallest-font-size: 14;
$base-font-size: 16;
$base-line-height: 1.5;
$base-line-height-in-px: ($base-font-size * $base-line-height); // maybe rename (across everywhere it appears) as px-base-line-height

$bp-xs-smallest-font-size: 14;
$bp-xs-base-font-size: 17;
$bp-xs-base-line-height: 1.5;
$bp-xs-px-base-line-height: ($bp-xs-base-font-size * $bp-xs-base-line-height);

$bp-s-smallest-font-size: 14;
$bp-s-base-font-size: 18;
$bp-s-base-line-height: 1.5;
$bp-s-px-base-line-height: ($bp-s-base-font-size * $bp-s-base-line-height);

$bp-m-smallest-font-size: 14;
$bp-m-base-font-size: 18;
$bp-m-base-line-height: 1.5;
$bp-m-px-base-line-height: ($bp-m-base-font-size * $bp-m-base-line-height);

$bp-l-smallest-font-size: 14;
$bp-l-base-font-size: 20;
$bp-l-base-line-height: 1.5;
$bp-l-px-base-line-height: ($bp-l-base-font-size * $bp-l-base-line-height);

$bp-xl-smallest-font-size: 14;
$bp-xl-base-font-size: 21;
$bp-xl-base-line-height: 1.5;
$bp-xl-px-base-line-height: ($bp-xl-base-font-size * $bp-xl-base-line-height);

